define('ember-file-upload/services/file-queue', ['exports', 'ember-file-upload/queue', 'ember-file-upload/computed/sum-by'], function (exports, _queue, _sumBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
      Setup a map of uploaders so they may be
      accessed by name via the `find` method.
       @constructor
     */

    init() {
      this._super(...arguments);
      Ember.set(this, 'queues', Ember.A());
      Ember.set(this, 'files', Ember.A());
    },

    /**
      The list of all files in queues. This automatically gets
      flushed when all the files in the queue have settled.
       Note that files that have failed need to be manually
      removed from the queue. This is so they can be retried
      without resetting the state of the queue, orphaning the
      file from its queue. Upload failures can happen due to a
      timeout or a server response. If you choose to use the
      `abort` method, the file will fail to upload, but will
      be removed from the requeuing proccess, and will be
      considered to be in a settled state.
       @property files
      @type {File[]}
      @default []
     */
    files: null,

    /**
      Flushes the `files` property when they have settled. This
      will only flush files when all files have arrived at a terminus
      of their state chart.
       ```
          .------.     .---------.     .--------.
      o--| queued |-->| uploading |-->| uploaded |
          `------`     `---------`     `--------`
             ^              |    .-------.
             |              |`->| aborted |
             |              |    `-------`
             |  .------.    |    .---------.
             `-| failed |<-` `->| timed_out |-.
             |  `------`         `---------`  |
             `-------------------------------`
      ```
       Files *may* be requeued by the user in the `failed` or `timed_out`
      states.
       @type {Observer}
     */
    flushFilesWhenSettled: Ember.observer('files.@each.state', function () {
      let files = Ember.get(this, 'files');
      let allFilesHaveSettled = files.every(function (file) {
        return ['uploaded', 'aborted'].indexOf(file.state) !== -1;
      });

      if (files.length === 0) {
        return;
      }

      if (allFilesHaveSettled) {
        Ember.get(this, 'files').forEach(file => Ember.set(file, 'queue', null));
        Ember.set(this, 'files', Ember.A());
      }
    }),

    /**
      The total size of all files currently being uploaded in bytes.
       @computed size
      @type Number
      @default 0
      @readonly
     */
    size: (0, _sumBy.default)('files', 'size'),

    /**
      The number of bytes that have been uploaded to the server.
       @computed loaded
      @type Number
      @default 0
      @readonly
     */
    loaded: (0, _sumBy.default)('files', 'loaded'),

    /**
      The current progress of all uploads, as a percentage in the
      range of 0 to 100.
       @computed progress
      @type Number
      @default 0
      @readonly
     */
    progress: Ember.computed('size', 'loaded', {
      get() {
        let percent = Ember.get(this, 'loaded') / Ember.get(this, 'size') || 0;
        return Math.floor(percent * 100);
      }
    }),

    /**
      Returns a queue with the given name
       @method find
      @param {String} name The name of the queue to find
      @return {Queue} The queue or null if it doesn't exist yet.
     */
    find(name) {
      return Ember.get(this, 'queues').findBy('name', name);
    },

    /**
      Create a new queue with the given name.
       @method create
      @param {String} name The name of the queue to create
      @return {Queue} The new queue.
     */
    create(name) {
      (true && !(this.find(name) == null) && Ember.assert(`Queue names are required to be unique. "${name}" has already been reserved.`, this.find(name) == null));


      let queue = _queue.default.create({ name, fileQueue: this });
      Ember.get(this, 'queues').push(queue);
      Ember.run.once(this, 'notifyPropertyChange', 'queues');

      return queue;
    }
  });
});