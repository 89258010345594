define("ember-cli-clipboard/helpers/is-clipboard-supported", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.isClipboardSupported = void 0;
  const isClipboardSupported = window && window.ClipboardJS ? window.ClipboardJS.isSupported : () => false;
  _exports.isClipboardSupported = isClipboardSupported;

  var _default = Ember.Helper.helper(isClipboardSupported);

  _exports.default = _default;
});